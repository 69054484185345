import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const LandingPage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      {/* Header */}
      <Header title="EHO Compliance Tool" />

      {/* Main Content */}
      <main className="flex-1 p-8">
        <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          <button
            className="bg-green-500 text-white p-4 rounded-md shadow hover:bg-green-600"
            onClick={() => window.location.href = '/temperature'}
          >
            Temperature Recording
          </button>
          <button
            className="bg-blue-500 text-white p-4 rounded-md shadow hover:bg-blue-600"
            onClick={() => window.location.href = '/allergens'}
          >
            Allergen Advice
          </button>
          <button
            className="bg-purple-500 text-white p-4 rounded-md shadow hover:bg-purple-600"
            onClick={() => window.location.href = '/staff'}
          >
            Staff Training
          </button>
          <button
            className="bg-yellow-500 text-white p-4 rounded-md shadow hover:bg-yellow-600"
            onClick={() => window.location.href = '/cleaning'}
          >
            Cleaning Records
          </button>
          <button
            className="bg-red-500 text-white p-4 rounded-md shadow hover:bg-red-600"
            onClick={() => window.location.href = '/reports'}
          >
            Reports
          </button>
          <button
            className="bg-indigo-500 text-white p-4 rounded-md shadow hover:bg-indigo-600"
            onClick={() => window.location.href = '/notes'}
          >
            Notes
          </button>
        </section>

        {/* Centralised Admin Button */}
        <section className="flex justify-center mb-12">
          <button
            className="bg-teal-600 text-white text-lg font-semibold py-4 px-8 rounded-lg shadow-lg hover:bg-teal-700"
            onClick={() => window.location.href = '/admin'}
          >
            Admin Dashboard
          </button>
        </section>

        <section className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <button
            className="bg-blue-800 text-white p-4 rounded-md shadow hover:bg-blue-900"
            onClick={() => window.location.href = '/menu'}
          >
            Customer Menu
          </button>
          <button
            className="bg-gray-500 text-white p-4 rounded-md shadow hover:bg-gray-600"
            onClick={() => window.location.href = 'https://www.food.gov.uk/business-guidance/safer-food-better-business-sfbb'}
          >
            Gov.uk SFBB
          </button>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default LandingPage;