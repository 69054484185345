import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white text-center p-4">
      <p>&copy; 2025 EHO Compliance Services &copy; 2025 EHO Tool</p>
    </footer>
  );
};

export default Footer;